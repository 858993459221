<template>
  <transition v-show="!sunrise" name="fade">
    <Stars :stars="stars.sm" />
  </transition>
  <div>
    <div class="bg-cover" ref="bgCover">
    </div>
    <svg
      enable-background="new 0 0 792 612"
      version="1.1"
      viewBox="0 0 869.79157 920.69574"
      id="svg152"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
      width="792.09998"
      height="612.09998"
      >
      <linearGradient
        id="t"
        x1="396"
        x2="396"
        y1="-9.0948999e-13"
        y2="456"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#202B48"
          offset=".028225"
          id="stop4" />
        <stop
          stop-color="#5B72A6"
          offset=".3051"
          id="stop6" />
        <stop
          stop-color="#977AA6"
          offset=".5308"
          id="stop8" />
        <stop
          stop-color="#D19EAF"
          offset=".7102"
          id="stop10" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop12" />
      </linearGradient>
      <rect
        data-sunset-stop="true"
        data-sunset="1"
        class="st0"
        width="792"
        height="456"
        id="rect15"
        x="3.5815795e-13"
        y="0"
        style="fill:url(#t)" />
      <radialGradient
        id="u"
        cx="364.35001"
        cy="411.29001"
        r="55.708"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#F4F3B0"
          offset=".010256"
          id="stop61" />
        <stop
          stop-color="#F9ED32"
          offset="1"
          id="stop63" />
      </radialGradient>
      <circle
        data-sunset="1"
        class="st3"
        cx="364.29999"
        cy="411.29999"
        r="55.700001"
        id="circle66"
        style="fill:url(#u)" />
      <linearGradient
        id="v"
        x1="217.2"
        x2="217.2"
        y1="110.9"
        y2="263.03"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0,193.64345)">
        <stop
          stop-color="#BA9BC9"
          offset="0"
          id="stop68" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop70" />
      </linearGradient>
      <linearGradient
        id="w"
        x1="663.58002"
        x2="663.58002"
        y1="29.903999"
        y2="105.97"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0,193.64345)">
        <stop
          stop-color="#BA9BC9"
          offset="0"
          id="stop75" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop77" />
      </linearGradient>
      <linearGradient
        id="x"
        x1="267.39001"
        x2="267.39001"
        y1="55.903999"
        y2="122"
        gradientTransform="matrix(-1.1403,0,0,1,860.91,193.64345)"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#BA9BC9"
          offset="0"
          id="stop82" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop84" />
      </linearGradient>
      <linearGradient
        id="y"
        x1="248.47"
        x2="248.47"
        y1="327.57001"
        y2="383.60001"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0,193.64345)">
        <stop
          stop-color="#BA9BC9"
          offset="0"
          id="stop89" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop91" />
      </linearGradient>
      <linearGradient
        id="z"
        x1="77"
        x2="77"
        y1="299.92999"
        y2="355.95999"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0,193.64345)">
        <stop
          stop-color="#BA9BC9"
          offset="0"
          id="stop96" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop98" />
      </linearGradient>
      <linearGradient
        id="aa"
        x1="598.53003"
        x2="598.53003"
        y1="288.97"
        y2="345"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0,193.64345)">
        <stop
          stop-color="#BA9BC9"
          offset="0"
          id="stop103" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop105" />
      </linearGradient>
      <linearGradient
        id="ab"
        x1="532.94"
        x2="532.94"
        y1="293.32001"
        y2="369.39001"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0,193.64345)">
        <stop
          stop-color="#BA9BC9"
          offset="0"
          id="stop110" />
        <stop
          stop-color="#F4F3B4"
          offset=".9949"
          id="stop112" />
      </linearGradient>
      <g
        id="g379"
        inkscape:label="cloud"
        transform="translate(0,-193.64345)">
        <path
          data-animate="cloud"
          class="st4"
          d="m 77,446.64345 c -16.7,-3.9 -27.4,-20.3 -24.2,-36.8 3.2,-16.9 20,-28.4 37.2,-25.2 -1,-10.9 6.4,-20.8 17,-23 10.5,-2.2 21.1,3.8 24.6,14 -0.6,-3 -3.3,-18.2 6.4,-33 9.4,-14.5 23.9,-18.3 27,-19 1.9,-10.3 10.2,-18.1 20,-19 11.2,-1.1 22.1,7 24.4,19 16.3,-5.9 34.3,-0.9 44.6,12 8.7,11 10.5,26.1 5,39.5 6.7,-11 19.5,-16.8 32,-14.5 12.9,2.4 23.1,12.9 25,26 0.6,-0.3 14,-7.6 27,0 9.2,5.4 14.4,16.1 13,27 4.9,-6.7 13.2,-8.9 19,-6 5.9,3 8.4,12.3 6.6,18.8 -7.2,25.2 -83.6,28.2 -83.6,28.2 0,0 -48.4,2.3 -99,2 -47.8,-0.3 -71.7,-2.8 -82,-4 -16.5,-1.9 -30.3,-4.2 -40,-6 z"
          id="path73"
          style="fill:url(#v)" />
        <path
          data-animate="cloud"
          class="st5"
          d="m 572.2,294.64345 c -10.9,-1.9 -17.8,-10.2 -15.8,-18.4 2.1,-8.5 13,-14.2 24.3,-12.6 -0.6,-5.4 4.2,-10.4 11.1,-11.5 6.8,-1.1 13.8,1.9 16.1,7 -0.4,-1.5 -2.1,-9.1 4.1,-16.5 6.1,-7.2 15.6,-9.1 17.6,-9.5 1.2,-5.2 6.7,-9 13,-9.5 7.3,-0.5 14.4,3.5 15.9,9.5 10.6,-2.9 22.4,-0.5 29.1,6 5.7,5.5 6.9,13.1 3.3,19.7 4.3,-5.5 12.7,-8.4 20.9,-7.2 8.4,1.2 15,6.4 16.3,13 0.4,-0.2 9.1,-3.8 17.6,0 6,2.7 9.4,8 8.5,13.5 3.2,-3.4 8.6,-4.5 12.4,-3 3.9,1.5 5.5,6.1 4.3,9.4 -4.7,12.6 -54.5,14.1 -54.5,14.1 0,0 -31.6,1.2 -64.6,1 -31.2,-0.2 -46.8,-1.4 -53.5,-2 -10.8,-1 -19.8,-2.1 -26.1,-3 z"
          id="path80"
          style="fill:url(#w)" />
        <path
          data-animate="cloud"
          class="st6"
          d="m 618.6,311.24345 c 7.4,-1.7 12.2,-8.8 10.8,-16 -1.4,-7.4 -8.9,-12.3 -16.6,-10.9 0.4,-4.7 -2.9,-9 -7.6,-10 -4.7,-0.9 -9.4,1.7 -11,6.1 0.3,-1.3 1.5,-7.9 -2.8,-14.3 -4.2,-6.3 -10.7,-7.9 -12,-8.3 -0.8,-4.5 -4.6,-7.8 -8.9,-8.3 -5,-0.5 -9.9,3.1 -10.9,8.3 -7.3,-2.6 -15.3,-0.4 -19.9,5.2 -3.9,4.8 -4.7,11.4 -2.2,17.2 -3,-4.8 -8.7,-7.3 -14.3,-6.3 -5.8,1 -10.3,5.6 -11.2,11.3 -0.3,-0.1 -6.2,-3.3 -12,0 -4.1,2.3 -6.4,7 -5.8,11.7 -2.2,-2.9 -5.9,-3.9 -8.5,-2.6 -2.6,1.3 -3.8,5.3 -2.9,8.2 3.2,10.9 37.3,12.2 37.3,12.2 0,0 21.6,1 44.2,0.9 21.3,-0.1 32,-1.2 36.6,-1.7 7.2,-0.9 13.3,-1.9 17.7,-2.7 z"
          id="path87"
          style="fill:url(#x)" />
        <path
          data-animate="cloud"
          class="st7"
          d="m 183.5,573.54345 c -7.7,-1.4 -12.7,-7.5 -11.2,-13.6 1.5,-6.2 9.3,-10.5 17.3,-9.3 -0.5,-4 3,-7.7 7.9,-8.5 4.9,-0.8 9.8,1.4 11.4,5.2 -0.3,-1.1 -1.5,-6.7 2.9,-12.2 4.4,-5.3 11.1,-6.7 12.5,-7 0.9,-3.8 4.7,-6.7 9.3,-7 5.2,-0.4 10.2,2.6 11.3,7 7.6,-2.2 15.9,-0.3 20.7,4.4 4,4 4.9,9.6 2.3,14.5 3.1,-4.1 9,-6.2 14.8,-5.3 6,0.9 10.7,4.7 11.6,9.6 0.3,-0.1 6.5,-2.8 12.5,0 4.3,2 6.7,5.9 6,9.9 2.3,-2.5 6.1,-3.3 8.8,-2.2 2.7,1.1 3.9,4.5 3,6.9 -3.3,9.3 -38.7,10.4 -38.7,10.4 0,0 -22.5,0.9 -45.9,0.7 -22.2,-0.1 -33.3,-1 -38,-1.5 -7.6,-0.5 -14,-1.3 -18.5,-2 z"
          id="path94"
          style="fill:url(#y)" />
        <path
          data-animate="cloud"
          class="st8"
          d="m 12,545.94345 c -7.7,-1.4 -12.7,-7.5 -11.2,-13.6 1.5,-6.2 9.3,-10.5 17.3,-9.3 -0.5,-4 3,-7.7 7.9,-8.5 4.9,-0.8 9.8,1.4 11.4,5.2 -0.3,-1.1 -1.5,-6.7 2.9,-12.2 4.4,-5.3 11.1,-6.7 12.5,-7 0.9,-3.8 4.7,-6.7 9.3,-7 5.2,-0.4 10.2,2.6 11.3,7 7.6,-2.2 15.9,-0.3 20.7,4.4 4,4 4.9,9.6 2.3,14.5 3.1,-4.1 9,-6.2 14.8,-5.3 6,0.9 10.7,4.7 11.6,9.6 0.3,-0.1 6.5,-2.8 12.5,0 4.3,2 6.7,5.9 6,9.9 2.3,-2.5 6.1,-3.3 8.8,-2.2 2.7,1.1 3.9,4.5 3,6.9 -3.3,9.3 -38.7,10.4 -38.7,10.4 0,0 -22.5,0.9 -45.9,0.7 -22.2,-0.1 -33.3,-1 -38,-1.5 -7.6,-0.5 -14,-1.4 -18.5,-2 z"
          id="path101"
          style="fill:url(#z)" />
        <path
          data-animate="cloud"
          class="st9"
          d="m 533.5,534.94345 c -7.7,-1.4 -12.7,-7.5 -11.2,-13.6 1.5,-6.2 9.3,-10.5 17.3,-9.3 -0.5,-4 3,-7.7 7.9,-8.5 4.9,-0.8 9.8,1.4 11.4,5.2 -0.3,-1.1 -1.5,-6.7 2.9,-12.2 4.4,-5.3 11.1,-6.7 12.5,-7 0.9,-3.8 4.7,-6.7 9.3,-7 5.2,-0.4 10.2,2.6 11.3,7 7.6,-2.2 15.9,-0.3 20.7,4.4 4,4 4.9,9.6 2.3,14.5 3.1,-4.1 9,-6.2 14.8,-5.3 6,0.9 10.7,4.7 11.6,9.6 0.3,-0.1 6.5,-2.8 12.5,0 4.3,2 6.7,5.9 6,9.9 2.3,-2.5 6.1,-3.3 8.8,-2.2 2.7,1.1 3.9,4.5 3,6.9 -3.3,9.3 -38.7,10.4 -38.7,10.4 0,0 -22.5,0.9 -45.9,0.7 -22.2,-0.1 -33.3,-1 -38,-1.5 -7.6,-0.5 -13.9,-1.3 -18.5,-2 z"
          id="path108"
          style="fill:url(#aa)" />
        <path
          data-animate="cloud"
          class="st10"
          d="m 437,558.04345 c -11.4,-1.9 -18.7,-10.2 -16.6,-18.4 2.2,-8.5 13.7,-14.2 25.5,-12.6 -0.7,-5.4 4.4,-10.4 11.6,-11.5 7.2,-1.1 14.4,1.9 16.9,7 -0.4,-1.5 -2.2,-9.1 4.4,-16.5 6.4,-7.2 16.4,-9.1 18.5,-9.5 1.3,-5.2 7,-9 13.7,-9.5 7.7,-0.5 15.1,3.5 16.7,9.5 11.2,-2.9 23.5,-0.5 30.5,6 5.9,5.5 7.2,13.1 3.4,19.8 4.6,-5.5 13.3,-8.4 21.9,-7.2 8.9,1.2 15.8,6.4 17.1,13 0.4,-0.2 9.6,-3.8 18.5,0 6.3,2.7 9.8,8 8.9,13.5 3.4,-3.4 9.1,-4.5 13,-3 4.1,1.5 5.8,6.1 4.5,9.4 -4.9,12.6 -57.1,14.1 -57.2,14.1 0,0 -33.1,1.2 -67.7,1 -32.7,-0.2 -49.1,-1.4 -56.1,-2 -11.4,-1.1 -20.8,-2.2 -27.5,-3.1 z"
          id="path115"
          style="fill:url(#ab)" />
      </g>
      <linearGradient
        id="o"
        x1="399.85999"
        x2="399.85999"
        y1="288"
        y2="488.37"
        gradientTransform="matrix(-1,0,0,1,795.86,0)"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#00A79D"
          offset=".010236"
          id="stop117" />
        <stop
          stop-color="#1B75BC"
          offset="1"
          id="stop119" />
      </linearGradient>
      <path
        class="st11"
        d="m 792,379.6 c -3.6,-4.3 -7.2,-8.5 -10.7,-12.8 0.1,1 1,12.2 -5.1,20.1 -5.5,7.1 -12.4,5.9 -13.4,5.7 -2.1,0.2 -14.7,1.3 -23.7,-11.5 -3.5,-5 -5.3,-10.5 -6.2,-14.3 l -16.5,12.9 C 702,360.9 682.9,339.4 658.8,319.5 642.2,305.9 626.5,295.7 612.5,288 c -5,12.7 -18.5,42.4 -45.2,63 -25.4,19.6 -49.2,20.5 -59.6,20.1 -3.1,-1.1 -9.1,-3.9 -14.4,-11.5 -2.5,-3.5 -4.1,-7.1 -5.1,-10 -8.4,12.9 -22.1,31 -42.2,45.8 -24,17.8 -75.9,27.1 -82.3,20.1 -8.6,-9.6 -8.3,-24.2 -8.2,-25.8 -8.2,10 -16.5,20.1 -24.7,30.1 -11.7,-13.1 -27.4,-28.2 -47.3,-41.5 -18,-12.1 -34.7,-19.6 -48.3,-24.3 -11.8,12.5 -48.1,48.7 -91.5,55.9 -19.7,3.3 -42.1,10.5 -52.4,-7.2 -8.1,-13.7 -5,-34.9 -3.1,-44.4 -15.9,11.6 -33,26.1 -50.4,44.4 -5.4,5.7 -10.6,11.5 -15.4,17.2 -5.9,-1.8 -22,-12.7 -22,-12.7 L 0,488.4 c 0,0 525.6,-2.8 792,-4.2"
        id="path122"
        style="fill:url(#o)" />
      <linearGradient
        id="p"
        x1="396"
        x2="396"
        y1="327"
        y2="466.98999"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#00A9BD"
          offset="0"
          id="stop124" />
        <stop
          stop-color="#006582"
          offset="1"
          id="stop126" />
      </linearGradient>
      <path
        class="st12"
        d="m 0,392.3 c 4,-3.4 8,-6.9 12.1,-10.3 -0.1,0.7 -1,8.5 5,14 5.4,4.9 12.1,4.1 13,4 2.1,0.2 14.3,0.9 23,-8 3.4,-3.5 5.1,-7.3 6,-10 5.3,3 10.7,6 16,9 14,-13.2 32.6,-28.2 56,-42 16.1,-9.5 31.4,-16.6 45,-22 4.9,8.9 18,29.6 44,44 24.7,13.7 47.8,14.3 58,14 3,-0.8 8.9,-2.7 14,-8 2.4,-2.5 4,-5 5,-7 8.1,9 21.5,21.6 41,32 23.3,12.4 73.8,19 80,14 8.3,-6.7 8,-16.9 8,-18 l 24,21 c 11.4,-9.1 26.7,-19.7 46,-29 17.5,-8.5 33.7,-13.7 47,-17 11.5,8.7 46.8,34 89,39 19.2,2.3 40.9,7.4 51,-5 7.8,-9.6 4.8,-24.3 3,-31 15.5,8.1 32.1,18.2 49,31 5.3,4 10.3,8 15,12 5.8,-1.3 13.5,-3.7 21.4,-8.9 8.4,-5.5 20.5,-10.2 20.6,-10.4 V 467 L 0,464"
        id="path129"
        style="fill:url(#p)" />
      <linearGradient
        id="q"
        x1="396"
        x2="396"
        y1="392.85999"
        y2="555.38"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#509AB9"
          offset="0"
          id="stop131" />
        <stop
          stop-color="#004F68"
          offset="1"
          id="stop133" />
      </linearGradient>
      <path
        class="st13"
        d="m 0.5,418 c 19.2,-8.6 43.3,-17 71.5,-21.6 80.5,-13.2 135.5,14 225,35.7 42.3,10.2 100.2,15.8 216,27 123,11.9 279,20.6 279,20.6 v 38.4 c 0,0 -779.3,34.2 -792,37.4 C 0.5,418 0,555.4 0.5,418 Z"
        id="path136"
        style="fill:url(#q)" />
      <linearGradient
        id="r"
        x1="396.26001"
        x2="396.26001"
        y1="445.88"
        y2="578.60999"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#56A4C4"
          offset="0"
          id="stop138" />
        <stop
          stop-color="#003151"
          offset="1"
          id="stop140" />
      </linearGradient>
      <path
        class="st14"
        d="m 0.5,452.9 c 69.9,20.9 191.2,53.6 334,24.1 15.1,-3.1 9.9,-2.6 37,-9 C 503,436.9 792,447.7 792,447.7 c 0,0 0,96.5 0,95.8 C 792,542.8 254.7,647.4 1.7,498.9 0.1,465.7 1.5,495.7 0.7,459 0.6,457.2 0.6,455.1 0.5,452.9 Z"
        id="path143"
        style="fill:url(#r)" />
      <linearGradient
        id="s"
        x1="396.5"
        x2="396.5"
        y1="500.70001"
        y2="612.04999"
        gradientUnits="userSpaceOnUse">
        <stop
          stop-color="#3BA9DC"
          offset="0"
          id="stop145" />
        <stop
          stop-color="#003455"
          offset="1"
          id="stop147" />
      </linearGradient>
      <path
        class="st15"
        d="M 792,500.7 C 675.4,514.7 543.9,524.2 399.8,524.3 253.1,524.4 119.3,514.9 1,500.7 v 111.4 h 791 z"
        id="path150"
        style="fill:url(#s)" />
    </svg>
  </div>
</template>

<script>
import Stars from '@/components/Stars.vue';

export default {
  name: 'BackgroundImage',
  components: {
    Stars,
  },
  data() {
    return {
      elements: [],
      selector: 'sunset',
      sunrise: false,
      clouds: [],
      stars: {
        sm: {
          qty: 1000,
          size: 0.6,
        },
        md: {
          qty: 400,
          size: 0.8,
        },
        lg: {
          qty: 0,
          size: 1,
        }
      },
    };
  },
  methods: {
    handleScroll() {
      this.elements.forEach(el => {
        const winY = window.scrollY;
        const winH = window.outerHeight;

        if(winY === 0) this.sunrise = true;
        else this.sunrise = false;
        
        this.$refs.bgCover.style.opacity = winY / 500;
        if(winY >= winH / 4) return;

        el.style.transition = `all .2s linear`;
        el.style.transform = `translateY(${winY}px)`;
      });
    },
    animateClouds() {
      this.clouds.forEach((cloud, i) => {
        cloud.classList.add('clouds-animation');
        if(i < 2) {
          cloud.style.animationDuration = '700s'
        }
        if(i > 2) {
          cloud.style.animationDuration = '400s';
        }
        if(i > 3) {
          cloud.style.animationDuration = '100s';
        }
        else {
          cloud.style.animationDuration = '130s';
        }
      });
    },
  },
  mounted() {
    this.elements = document.querySelectorAll('[data-sunset]');
    this.clouds = document.querySelectorAll("[data-animate='cloud']");
    document.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
    this.animateClouds();
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style lang="scss" scoped>
div {
  position: fixed;
  top: -65%;
  left: -225%;
  transition: all .2s ease;

  @media (min-width: 460px) {
    top: -35%;
    left: -180%;
  }

  @media (min-width: 576px) {
    left: -135%;
  }

  @media (min-width: 768px) {
    left: -90%;
  }

  @media (min-width: 992px) {
    left: -55%;
  }

  @media (min-width: 1200px) {
    left: -40%;
  }

  @media (min-width: 1400px) {
    left: -25%;
  }

  @media (min-width: 1400px) {
    left: -15%;
  }

  @media (min-width: 1600px) {
    left: -10%;
  }

  .bg-cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #090A0F;
    opacity: 1;
  }
}

svg {
  height: 2500px;
  width: 2500px;
}

.st0 { fill:url(#t); }
.st1 { opacity:0.59; }
.st3 { fill:url(#u); }
.st4 { opacity:0.68;fill:url(#v); }
.st5 { opacity:0.82;fill:url(#w); }
.st6 { opacity:0.92;fill:url(#x); }
.st7 { opacity:0.79;fill:url(#y); }
.st8 { opacity:0.79;fill:url(#z); }
.st9 { opacity:0.79;fill:url(#aa); }
.st10 { opacity:0.79;fill:url(#ab); }
.st11 { fill:url(#o); }
.st12 { fill:url(#p); }
.st13 { fill:url(#q); }
.st14 { fill:url(#r); }
.st15 { fill:url(#s); }
.st16 { fill:url(#SVGID_15_); }
.st17 { fill:url(#SVGID_16_); }
.st18 { opacity:0.68;fill:url(#SVGID_17_); }
.st19 { opacity:0.82;fill:url(#SVGID_18_); }
.st20 { opacity:0.92;fill:url(#SVGID_19_); }
.st21 { opacity:0.79;fill:url(#SVGID_20_); }
.st22 { opacity:0.79;fill:url(#SVGID_21_); }
.st23 { opacity:0.79;fill:url(#SVGID_22_); }
.st24 { opacity:0.79;fill:url(#SVGID_23_); }
.st25 { fill:url(#SVGID_24_); }
.st26 { fill:url(#SVGID_25_); }
.st27 { fill:url(#SVGID_26_); }
.st28 { fill:url(#SVGID_27_); }
.st29 { fill:url(#SVGID_28_); }
.st30 { fill:url(#SVGID_29_); }
.st31 { fill:url(#SVGID_30_); }
.st32 { opacity:0.68;fill:url(#SVGID_31_); }
.st33 { opacity:0.82;fill:url(#SVGID_32_); }
.st34 { opacity:0.92;fill:url(#SVGID_33_); }
.st35 { opacity:0.79;fill:url(#SVGID_34_); }
.st36 { opacity:0.79;fill:url(#SVGID_35_); }
.st37 { opacity:0.79;fill:url(#SVGID_36_); }
.st38 { opacity:0.79;fill:url(#SVGID_37_); }
.st39 { fill:url(#SVGID_38_); }
.st40 { fill:url(#SVGID_39_); }
.st41 { fill:url(#SVGID_40_); }
.st42 { fill:url(#SVGID_41_); }
.st43 { fill:url(#SVGID_42_); }
.st44 { fill:url(#SVGID_43_); }
.st45 { fill:url(#SVGID_44_); }
.st46 { opacity:0.68;fill:url(#SVGID_45_); }
.st47 { opacity:0.82;fill:url(#SVGID_46_); }
.st48 { opacity:0.92;fill:url(#SVGID_47_); }
.st49 { opacity:0.79;fill:url(#SVGID_48_); }
.st50 { opacity:0.79;fill:url(#SVGID_49_); }
.st51 { opacity:0.79;fill:url(#SVGID_50_); }
.st52 { opacity:0.79;fill:url(#SVGID_51_); }
.st53 { fill:url(#SVGID_52_); }
.st54 { fill:url(#SVGID_53_); }
.st55 { fill:url(#SVGID_54_); }
.st56 { fill:url(#SVGID_55_); }
.st57 { fill:url(#SVGID_56_); }
.st58 { fill:url(#SVGID_57_); }
.st59 { fill:url(#SVGID_58_); }
.st60 { opacity:0.68;fill:url(#SVGID_59_); }
.st61 { opacity:0.82;fill:url(#SVGID_60_); }
.st62 { opacity:0.92;fill:url(#SVGID_61_); }
.st63 { opacity:0.79;fill:url(#SVGID_62_); }
.st64 { opacity:0.79;fill:url(#SVGID_63_); }
.st65 { opacity:0.79;fill:url(#SVGID_64_); }
.st66 { opacity:0.79;fill:url(#SVGID_65_); }
.st67 { fill:url(#SVGID_66_); }
.st68 { fill:url(#SVGID_67_); }
.st69 { fill:url(#SVGID_68_); }
.st70 { fill:url(#SVGID_69_); }
.st71 { fill:url(#SVGID_70_); }

.clouds-animation {
  animation-name: clouds;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes clouds {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
</style>
