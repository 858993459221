<template>
  <header>
    <div class="text fade-in">
      <h1>Hi! I am Patryk</h1>
      <p class="subtitle">Full-Stack Web Developer</p>
      <p class="content">I design and develop amazing web applications. Some of my recent projects are listed below.</p>
    </div>
    <div class="svg slide-in">
      <svg
        width="865.76"
        height="682.89"
        viewBox="0 0 865.76 682.89"
        version="1.1"
        id="svg294">
        
        <defs
          id="defs2">
        </defs>
        <rect
          x="56.25"
          y="90.08"
          width="665.31"
          height="410.45"
          rx="16.13"
          fill="#3f4347"
          id="rect4" />
        <rect
          x="71.39"
          y="102.4"
          width="635.04"
          height="385.81"
          rx="12.01"
          fill="#ebebeb"
          stroke="#000"
          stroke-miterlimit="10"
          id="rect6" />
        <g
          class="fade"
          id="g461">
          <rect
            x="109.76"
            y="31.64"
            width="203.46"
            height="291.47"
            rx="16.36"
            fill="#ccc"
            id="rect8" />
          <rect
            x="121.03"
            y="43.61"
            width="180.94"
            height="266.12"
            rx="16.36"
            fill="#b3b3b3"
            id="rect10" />
          <rect
            x="129.47"
            y="87.26"
            width="164.74"
            height="216.14"
            rx="13.42"
            fill="none"
            stroke="#fff"
            stroke-miterlimit="10"
            id="rect12" />
          <rect
            x="137.22"
            y="55.58"
            width="130.95"
            height="7.04"
            rx="3.52"
            fill="#fff"
            id="rect14" />
          <rect
            x="153.41"
            y="71.77"
            width="114.76"
            height="7.04"
            rx="3.52"
            fill="#fff"
            id="rect16" />
          <path
            d="M238.06,244.8l9.51,2.51,4.15-15.76L242,229a41,41,0,0,0-3.13-17.78l9.21-5.68-8.55-13.88-9.81,6a41.12,41.12,0,0,0-11.65-8l3.13-11.85-15.76-4.16L202.19,186a40.93,40.93,0,0,0-15.28,2.42l-5.26-9.18-14.14,8.1,5.47,9.56a41.07,41.07,0,0,0-8.76,11.62l-9.74-2.57-4.15,15.76,9.53,2.52a41.16,41.16,0,0,0,1.86,15.43L151,246.26l8.55,13.88,10.18-6.28a40.85,40.85,0,0,0,12,9.61l-2.85,10.8,15.76,4.17,2.74-10.38a40.86,40.86,0,0,0,15.46-1.59l4.92,8.6,14.15-8.1-4.71-8.22A40.84,40.84,0,0,0,238.06,244.8Z"
            fill="#ccc"
            id="path124" />
          <path
            d="M210.91,201.18a26.76,26.76,0,1,1-20.3.22"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            id="path126" />
          <circle
            cx="201.05"
            cy="226.05"
            r="9.86"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            id="circle128" />
          <path
            d="M263.27,158.56l5,1.3,2.16-8.21-5.06-1.34a21.34,21.34,0,0,0-1.64-9.26l4.81-3L264,130.86,258.92,134a21.25,21.25,0,0,0-6.07-4.18l1.63-6.18-8.21-2.17-1.69,6.41a21.39,21.39,0,0,0-8,1.26l-2.73-4.78-7.38,4.22,2.86,5a21.13,21.13,0,0,0-4.57,6.06l-5.08-1.34-2.16,8.21,5,1.31a21.33,21.33,0,0,0,1,8.05l-5.62,3.46,4.46,7.23,5.31-3.27a21.24,21.24,0,0,0,6.27,5l-1.49,5.62,8.21,2.17,1.43-5.4a21.36,21.36,0,0,0,8.06-.83l2.56,4.48,7.38-4.22-2.46-4.29A21.38,21.38,0,0,0,263.27,158.56Z"
            fill="#ccc"
            id="path130" />
          <path
            d="M249.12,135.82a13.95,13.95,0,1,1-10.58.12"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            id="path132" />
          <circle
            cx="243.98"
            cy="148.78"
            r="5.14"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            id="circle134" />
          <line
            x1="129.47"
            y1="107.22"
            x2="294.22"
            y2="107.22"
            fill="none"
            stroke="#ffffff"
            stroke-miterlimit="10"
            id="line22"
            style="display:inline" />
        </g>
        <g
          class="fade"
          id="g473">
          <rect
            x="137.22"
            y="371.69"
            width="130.95"
            height="7.04"
            rx="3.52"
            fill="none"
            stroke="#000"
            stroke-miterlimit="10"
            id="rect18" />
          <rect
            x="153.41"
            y="387.88"
            width="114.76"
            height="7.04"
            rx="3.52"
            fill="none"
            stroke="#000"
            stroke-miterlimit="10"
            id="rect20" />
          <path
            d="M125.89,349.16H297.1a16.13,16.13,0,0,1,16.13,16.13V487.85a0,0,0,0,1,0,0H109.76a0,0,0,0,1,0,0V365.29A16.13,16.13,0,0,1,125.89,349.16Z"
            fill="#0071f2"
            data-primary="true"
            id="path24" />
          <path
            d="M138.88,361.13H284.11A17.85,17.85,0,0,1,302,379V487.85a0,0,0,0,1,0,0H121a0,0,0,0,1,0,0V379A17.85,17.85,0,0,1,138.88,361.13Z"
            fill="#fff"
            opacity="0.3"
            id="path26" />
          <path
            d="M129.47,488.21V410.56a5.79,5.79,0,0,1,5.78-5.78H285.62a5.78,5.78,0,0,1,5.78,5.78v77.65"
            fill="none"
            stroke="#fff"
            stroke-miterlimit="10"
            id="path28" />
          <line
            x1="129.47"
            y1="423.13"
            x2="291.4"
            y2="423.13"
            fill="none"
            stroke="#fff"
            stroke-miterlimit="10"
            id="line30" />
          <path
            d="M211.49,488.21V444.87a3.41,3.41,0,0,0-3.5-3.41c-10.1.28-39.26,4.8-50.61,46.39Z"
            fill="#fff"
            opacity="0.3"
            id="path32" />
          <path
            d="M217.24,480.27l7.16-41.2a5.12,5.12,0,0,1,5.85-4.17c9.19,1.46,28.16,6.75,32.62,25.66a5.38,5.38,0,0,1-2.88,6l-36.85,18.09a4.13,4.13,0,0,1-4.12-.27h0A4.13,4.13,0,0,1,217.24,480.27Z"
            fill="#fff"
            opacity="0.3"
            id="path34" />
          <path
            d="M235.78,487.85l26.82-8.3a3,3,0,0,1,3.76,2.16,10.82,10.82,0,0,1-.45,6.5Z"
            fill="#fff"
            opacity="0.3"
            id="path36" />
        </g>
        <g
          class="fade"
          id="g489">
          <rect
            x="349.84"
            y="66.84"
            width="335.12"
            height="138.69"
            rx="8.07"
            fill="#0071f2"
            data-primary="true"
            id="rect38" />
          <rect
            x="356.88"
            y="72.12"
            width="321.04"
            height="128.13"
            fill="#fff"
            opacity="0.3"
            id="rect40" />
          <rect
            x="368.84"
            y="131.61"
            width="16.9"
            height="59.14"
            fill="#fff"
            opacity="0.3"
            id="rect42" />
          <rect
            x="420.94"
            y="100.64"
            width="16.9"
            height="90.12"
            fill="#fff"
            opacity="0.3"
            id="rect44" />
          <rect
            x="473.75"
            y="113.31"
            width="16.9"
            height="77.44"
            fill="#fff"
            opacity="0.3"
            id="rect46" />
          <rect
            x="500.5"
            y="127.39"
            width="16.9"
            height="64.07"
            fill="#fff"
            opacity="0.3"
            id="rect48" />
          <rect
            x="525.14"
            y="141.47"
            width="16.9"
            height="49.99"
            fill="#fff"
            opacity="0.3"
            id="rect50" />
          <rect
            x="552.6"
            y="84.44"
            width="16.9"
            height="107.01"
            fill="#fff"
            opacity="0.3"
            id="rect52" />
          <rect
            x="579.35"
            y="93.6"
            width="16.9"
            height="97.86"
            fill="#fff"
            opacity="0.3"
            id="rect54" />
          <rect
            x="604.7"
            y="111.9"
            width="16.9"
            height="79.56"
            fill="#fff"
            opacity="0.3"
            id="rect56" />
          <rect
            x="630.74"
            y="171.04"
            width="16.9"
            height="20.42"
            fill="#fff"
            opacity="0.3"
            id="rect58" />
          <rect
            x="657.5"
            y="133.73"
            width="16.9"
            height="57.73"
            fill="#fff"
            opacity="0.3"
            id="rect60" />
          <rect
            x="395.6"
            y="152.74"
            width="16.9"
            height="38.02"
            fill="#fff"
            opacity="0.3"
            id="rect62" />
          <rect
            x="447.7"
            y="169.63"
            width="16.9"
            height="21.12"
            fill="#fff"
            opacity="0.3"
            id="rect64" />
        </g>
        <g
          class="fade"
          id="g502">
          <rect
            x="349.84"
            y="236.51"
            width="335.12"
            height="145.03"
            rx="11.07"
            fill="#ccc"
            id="rect66" />
          <rect
            x="356.88"
            y="243.56"
            width="322.45"
            height="129.54"
            fill="#b3b3b3"
            id="rect68" />
          <rect
            x="380.81"
            y="340.01"
            width="66.88"
            height="11.97"
            rx="5.98"
            fill="#fff"
            id="rect70" />
          <rect
            x="462.48"
            y="340.01"
            width="66.88"
            height="11.97"
            rx="5.98"
            fill="#fff"
            id="rect72" />
          <rect
            x="546.45"
            y="340.31"
            width="67.15"
            height="12.02"
            rx="6.01"
            fill="#fff"
            id="rect74" />
          <circle
            cx="412.49"
            cy="292.13"
            r="33.09"
            fill="#ccc"
            id="circle76" />
          <path
            d="M432.44,286.06a21.21,21.21,0,1,1-20.29-15c.59,0,1.18,0,1.76.07v-6.38c-.58,0-1.17-.05-1.76-.05a27.54,27.54,0,1,0,25.85,18Z"
            fill="#fff"
            id="path78" />
          <circle
            cx="579.35"
            cy="293.54"
            r="33.09"
            fill="#ccc"
            id="circle80" />
          <path
            d="M596.74,305.76a21.21,21.21,0,1,1-17.41-33.31c.59,0,1.18,0,1.76.07v-6.37c-.58,0-1.17-.06-1.76-.06a27.57,27.57,0,1,0,21.94,44.25Z"
            fill="#fff"
            id="path82" />
          <circle
            cx="495.57"
            cy="292.13"
            r="33.09"
            fill="#ccc"
            id="circle84" />
          <path
            d="M506.66,310.31A21.21,21.21,0,1,1,495.56,271c.52,0,1,0,1.56,0v-6.37c-.52,0-1.05,0-1.57,0a27.57,27.57,0,1,0,14.24,51.17Z"
            fill="#fff"
            id="path86" />
        </g>
        <path
          d="M357.91,398.44h319a8.07,8.07,0,0,1,8.07,8.07v81.34a0,0,0,0,1,0,0H349.84a0,0,0,0,1,0,0V406.51A8.07,8.07,0,0,1,357.91,398.44Z"
          fill="#0071f2"
          data-primary="true"
          id="path88" />
        <rect
          x="356.88"
          y="406.89"
          width="322.45"
          height="80.96"
          fill="#fff"
          opacity="0.3"
          id="rect90" />
        <rect
          x="438.54"
          y="422.38"
          width="71.11"
          height="5.63"
          fill="#fff"
          opacity="0.3"
          id="rect92" />
        <rect
          x="411.09"
          y="440.68"
          width="98.56"
          height="5.63"
          fill="#fff"
          opacity="0.3"
          id="rect94" />
        <rect
          x="389.26"
          y="449.13"
          width="120.39"
          height="5.63"
          fill="#fff"
          opacity="0.3"
          id="rect96" />
        <rect
          x="375.18"
          y="457.58"
          width="134.47"
          height="5.63"
          fill="#fff"
          opacity="0.3"
          id="rect98" />
        <rect
          x="447.7"
          y="466.03"
          width="61.95"
          height="5.63"
          fill="#fff"
          opacity="0.3"
          id="rect100" />
        <rect
          x="429.39"
          y="474.48"
          width="80.26"
          height="5.63"
          fill="#fff"
          opacity="0.3"
          id="rect102" />
        <rect
          x="412.49"
          y="483.63"
          width="97.16"
          height="4.22"
          fill="#fff"
          opacity="0.3"
          id="rect104" />
        <rect
          x="485.01"
          y="431.53"
          width="24.64"
          height="5.63"
          fill="#fff"
          opacity="0.3"
          id="rect106" />
        <rect
          x="527.25"
          y="422.38"
          width="71.11"
          height="5.63"
          transform="translate(1125.61 850.39) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect108" />
        <rect
          x="527.25"
          y="440.68"
          width="98.56"
          height="5.63"
          transform="translate(1153.07 887) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect110" />
        <rect
          x="527.25"
          y="449.13"
          width="120.39"
          height="5.63"
          transform="translate(1174.89 903.9) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect112" />
        <rect
          x="527.25"
          y="457.58"
          width="134.47"
          height="5.63"
          transform="translate(1188.97 920.79) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect114" />
        <rect
          x="527.25"
          y="466.03"
          width="61.95"
          height="5.63"
          transform="translate(1116.46 937.69) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect116" />
        <rect
          x="527.25"
          y="474.48"
          width="80.26"
          height="5.63"
          transform="translate(1134.76 954.59) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect118" />
        <rect
          x="527.25"
          y="483.63"
          width="97.16"
          height="4.22"
          transform="translate(1151.66 971.48) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect120" />
        <rect
          x="527.25"
          y="431.53"
          width="24.64"
          height="5.63"
          transform="translate(1079.14 868.7) rotate(-180)"
          fill="#fff"
          opacity="0.3"
          id="rect122" />
        <polygon
          points="677.81 507.15 696.65 507.15 696.65 623.46 677.81 623.46 677.81 507.15 677.81 507.15"
          fill="#5d6166"
          fill-rule="evenodd"
          id="polygon136" />
        <path
          d="M746,635.29c0-6.53-7.61-11.83-17-11.83h-76.3c-9.38,0-17,5.3-17,11.83"
          fill="none"
          stroke="#2e3135"
          stroke-miterlimit="10"
          stroke-width="10"
          id="path138" />
        <path
          d="M773.33,316.76H712.6L689.8,469.07h64a15.25,15.25,0,0,0,14.56-12.66l18.52-123.77c1.32-8.77-4.73-15.88-13.5-15.88"
          fill="#2e3135"
          id="path140" />
        <path
          d="M761.68,316.76H689a17.27,17.27,0,0,0-17.07,14.71L653.37,455.33a12,12,0,0,0,11.84,13.74h76.9a15.24,15.24,0,0,0,14.55-12.66l18.53-123.77c1.31-8.77-4.74-15.88-13.51-15.88"
          fill="#3f4347"
          id="path142" />
        <path
          d="M753.89,469.06H628.81a2.42,2.42,0,0,0-2.43,2.42,18,18,0,0,0,18,18h91.49a18,18,0,0,0,18-18v-2.42Z"
          fill="#5d6166"
          id="path144" />
        <path
          d="M716,507.15H658.47a6.5,6.5,0,0,1-6.5-6.5v-11.2h70.51v11.2a6.49,6.49,0,0,1-6.5,6.5"
          fill="#3f4347"
          id="path146" />
        <path
          d="M758.6,635.29A12.6,12.6,0,1,1,746,622.7a12.6,12.6,0,0,1,12.59,12.59"
          fill="#5d6166"
          id="path148" />
        <path
          d="M752.64,635.29a6.64,6.64,0,1,1-6.63-6.64,6.63,6.63,0,0,1,6.63,6.64"
          fill="#858789"
          id="path150" />
        <path
          d="M648,635.29a12.6,12.6,0,1,1-12.59-12.59A12.6,12.6,0,0,1,648,635.29"
          fill="#5d6166"
          id="path152" />
        <path
          d="M642,635.29a6.64,6.64,0,1,1-6.63-6.64,6.63,6.63,0,0,1,6.63,6.64"
          fill="#858789"
          id="path154" />
        <polygon
          points="677.81 507.15 696.65 507.15 696.65 518.42 677.81 512.38 677.81 507.15 677.81 507.15"
          fill="#2e3135"
          id="polygon156" />
        <path
          d="M554.66,589.11l-1.8,20.11s2.71,7.21,9.77,8.54a21.55,21.55,0,0,0,12-.92l7.42-31.21-27.39,3.48Z"
          fill="#f9b499"
          id="path158" />
        <path
          d="M585.51,646.69H508.68a1.29,1.29,0,0,1-1.29-1.29,34.18,34.18,0,0,1,.48-5.35c1-6,4.08-14.15,13.39-16.34,15.15-3.57,22.08-9,22.08-9s2.49-11.21,6.07-11.37,4.69,5.88,6.78,7.33,5.86,1.57,8.6,1.57c9.39,0,11.1-6.13,15.4-3.79,2.35,1.29,6.71,21,8.91,31.62.12.64.26,1.24.37,1.8a4,4,0,0,1-4,4.84"
          fill="#0071f2"
          data-primary="true"
          id="path160" />
        <path
          d="M585.51,646.69H508.68a1.29,1.29,0,0,1-1.29-1.29,34.18,34.18,0,0,1,.48-5.35H589.1c.12.64.26,1.24.37,1.8a4,4,0,0,1-4,4.84"
          fill="#2f2e41"
          data-secondary="true"
          id="path162" />
        <path
          d="M542,626.05a1,1,0,0,1-1-.62c-2.41-5.47-6-6.21-6.2-6.24a1.05,1.05,0,0,1,.36-2.07c.19,0,4.87.89,7.76,7.46a1.06,1.06,0,0,1-.54,1.39A1,1,0,0,1,542,626.05Zm7-2.54a1,1,0,0,1-1-.63c-2.4-5.47-6-6.2-6.19-6.23a1.05,1.05,0,0,1-.85-1.22,1,1,0,0,1,1.2-.85c.2,0,4.88.89,7.77,7.46a1.05,1.05,0,0,1-1,1.47Z"
          fill="#fff"
          id="path164" />
        <path
          d="M648.31,596.32,655,615.39s5.45,5.44,12.42,3.74A21.59,21.59,0,0,0,678,613.34l-6.13-31.49-23.52,14.47Z"
          fill="#f9b499"
          id="path166" />
        <path
          d="M618.26,421.8s-47.69,17.89-52,20.38-10.49,9.19-11.85,23.49-4.27,125-4.27,125l35.4,7.26,21.21-115.66L694,421.8Z"
          fill="#2f2e41"
          data-secondary="true"
          id="path168" />
        <path
          d="M618.26,421.8s-47.69,17.89-52,20.38-10.49,9.19-11.85,23.49-4.27,125-4.27,125l35.4,7.26,21.21-115.66L694,421.8Z"
          opacity="0.2"
          id="path170" />
        <path
          d="M710,394.72s-72.16,46-81,54.76c-6.65,6.58-13.61,15-9.53,32.23s24.26,116.2,24.26,116.2l34.61-7.54-12.09-113.2,47-10c13.24-4.36,22.23-12.38,22.23-26.32V421.8L710,394.72Z"
          fill="#2f2e41"
          data-secondary="true"
          id="path172" />
        <path
          d="M624.31,293.57s-6.19,4-9.11,9.53c-7.62,14.53-11.3,62.66-11.3,62.66l-5.24,29,28.83,4.76,9.29-72.79-12.47-33.12Z"
          fill="#0071f2"
          data-primary="true"
          id="path174" />
        <path
          d="M647.52,284.81s-19.76,3.62-26.65,11.66c-11.2,13.09,6.25,80.13,6.25,80.13l-.74,42.93s11.34,9.64,32.63,8.1c18.18-1.32,26.65-7.28,46.87-11.57s29.67,5.74,29.67,5.74l-5-18.68s-.1-98.81-2.83-104.94-30.19-10.67-30.19-10.67l-50-2.7Z"
          fill="#0071f2"
          data-primary="true"
          id="path176" />
        <path
          d="M683.18,253.13s.16,20.63,1.75,28.8l-19.88,17.78-7.69-17.1s.26-4.13-.8-6.65c-.92-2.19,26.62-22.83,26.62-22.83"
          fill="#f9b499"
          id="path178" />
        <path
          d="M683.08,255.09s-.5,20-10.2,25S657,276,657,276l26.05-20.93Z"
          fill="#f7a48b"
          id="path180" />
        <path
          d="M633.79,228.21s-3.29,4.2-1.13,8.63,7.14,5.2,7.14,5.2l.69-14.39-6.7.56Z"
          fill="#2f2e41"
          data-secondary="true"
          id="path182" />
        <path
          d="M638.61,233.58c.12,4.12,1.2,10,2.27,15.82,1,5.22,1.82,10.59,3,13.82,2.39,6.81,11.12,12.71,15.92,14,7.2,1.93,20.68-9.82,22.21-15.18a34.06,34.06,0,0,0,1.19-10.39l0-24.68s-10-9.56-22.92-9.78c-12.68-.22-21.88,8.56-21.65,16.4"
          fill="#f9b499"
          id="path184" />
        <path
          d="M653.64,229.45s-7.72,4.55-14.91,6.24a16,16,0,0,1,.34-5.75c1.32-4.07,14.57-.49,14.57-.49"
          fill="#f7a48b"
          id="path186" />
        <polygon
          points="683.18 232.67 683.16 244.41 687.87 245.64 690.2 228.75 683.18 232.67 683.18 232.67"
          fill="#2f2e41"
          data-secondary="true"
          id="polygon188" />
        <path
          d="M683.17,233.32c-2.34.45-11.18.13-13.47-5,0,0-8.17,6.08-16.06,1.13,0,0-22.29,8.34-27-8.17-3.29-11.47,3.86-21.8,14.82-21.79,5.07,0,8.91,4.6,8.91,4.6A19.65,19.65,0,0,1,665.9,202c9,2.48,10.89,7.89,10.89,7.89s13.42,2.7,15.21,12.42c1.42,7.69-6,10.47-8.83,11"
          fill="#2f2e41"
          data-secondary="true"
          id="path190" />
        <path
          d="M641.23,258a3.11,3.11,0,0,1-2.75-2.68l-1.16-5.06a4.17,4.17,0,0,1,.58-3.35,2.41,2.41,0,0,1,1.74-1l11.12-1a2.46,2.46,0,0,1,1.89.72,4.19,4.19,0,0,1,1.16,3.2V249a4.11,4.11,0,0,1,2.71-1.42,4.73,4.73,0,0,1,3.29,1.1l-.09-.41a4.18,4.18,0,0,1,.58-3.35,2.45,2.45,0,0,1,1.74-1l11.12-1a2.45,2.45,0,0,1,1.89.73,3.64,3.64,0,0,1,.88,1.36l12.13-4.62.38,1-12.26,4.67a5.39,5.39,0,0,1,0,.81l-.26,5.19c-.09,1.7-1.11,3-2.43,3.13l-9.7.85c-1.33.12-2.56-1-2.94-2.66l-.63-2.76c-.31-.39-1.77-2.11-3.63-2s-2.76,2.11-2.92,2.5l-.14,2.85c-.09,1.7-1.11,3-2.43,3.14l-9.7.85ZM651,245.94h-.1l-11.12,1a1.41,1.41,0,0,0-1,.61,3.17,3.17,0,0,0-.41,2.5l1.17,5.07c.25,1.12,1.06,1.92,1.82,1.85l9.69-.85c.8-.07,1.42-1,1.48-2.14l.26-5.19a3.2,3.2,0,0,0-.84-2.39A1.41,1.41,0,0,0,651,245.94Zm22.39-2h-.1l-11.12,1a1.38,1.38,0,0,0-1,.61,3.19,3.19,0,0,0-.41,2.5l1.17,5.07c.25,1.12,1,1.92,1.82,1.85l9.7-.86c.79-.06,1.41-1,1.47-2.13l.26-5.2a3.18,3.18,0,0,0-.84-2.39A1.44,1.44,0,0,0,673.34,244Z"
          fill="#2f2e41"
          data-secondary="true"
          id="path192" />
        <path
          d="M665.05,299.71s16.87-25.4,25.15-24.26,10.1,9.57,10,12.16,0,4-35.14,12.1"
          fill="#0071f2"
          data-primary="true"
          id="path194" />
        <path
          d="M665.05,299.71s-21.63-13.94-17.94-19.43a7,7,0,0,1,9.43-2.53c1.62,2.67,8.4,18.84,8.51,22"
          fill="#0071f2"
          data-primary="true"
          id="path196" />
        <path
          d="M683.16,239.94s7.27.52,7.57,6.47c.42,8.68-7.65,8.68-7.65,8.68l.08-15.15Z"
          fill="#f9b499"
          id="path198" />
        <path
          d="M651.08,393.38s-6.9-7.06-19-2.37l5.72-8.72,13.85-1.24,3.7,10-4.3,2.34Z"
          fill="#eda89f"
          id="path200" />
        <path
          d="M659.56,391s.31-3.39-1.39-5.64-5.3,3-7.82,3.41-8.51.19-8.8,3.92,3.94,5.45,10.49,3.08,7-2.68,7.52-4.77"
          fill="#eda89f"
          id="path202" />
        <path
          d="M659.84,395.87l21.69,7.25v-15.9s-22-4.63-25.16-5.6-8-3.07-9.92-3.48-4.71,1-5.78,1-5.62-1.84-8.06-.3-12,10.6-10.47,14.68,6,1.24,6,1.24c0,4.34,3.22,5.45,5.4,3.9,1.33-.94,1.18-1.11,1.63-4.43s4.75-5.47,5.37-5.78a5.9,5.9,0,0,1,4.84-.15c2,.67,3.3,1.37,5.66,5.1,1,1.59,6.95,3,8.76,2.49"
          fill="#f9b499"
          id="path204" />
        <path
          d="M716,294.67c10.25-3.44,15.72,8.34,18.67,16.22s16.56,80.3,14.46,88.59c-.79,3.14-5.62,8.52-10.69,10.06-11,3.36-45.85-.12-60.71-2.83-4.23-.78-5.6-3.59-5.6-3.59l5.08-18.06a5.69,5.69,0,0,1,5.65-3.8L715.6,383s-8-53.75-10-65.32,1.69-20.05,10.38-23"
          fill="#0071f2"
          data-primary="true"
          id="path206" />
        <rect
          x="588.26"
          y="357.87"
          width="70.37"
          height="1.05"
          transform="translate(201.28 940.55) rotate(-83.94)"
          fill="#0071f2"
          data-primary="true"
          id="rect208" />
        <g
          opacity="0.2"
          id="g212">
          <rect
            x="588.26"
            y="357.87"
            width="70.37"
            height="1.05"
            transform="translate(201.28 940.55) rotate(-83.94)"
            id="rect210" />
        </g>
        <polygon
          points="677.69 383.98 667.35 381.52 664.01 399.79 675.94 402.62 677.69 383.98 677.69 383.98"
          fill="#0071f2"
          data-primary="true"
          id="polygon214" />
        <polygon
          points="677.69 383.98 667.35 381.52 664.01 399.79 675.94 402.62 677.69 383.98 677.69 383.98"
          opacity="0.2"
          id="polygon216" />
        <path
          d="M678.11,384.31l-.83-.66c.14-.17,3.34-4.13,7.42-4.13,3.55,0,26,2.38,30.28,2.84l-9-58.88,1-.15,9.16,60.22-.68-.07c-.27,0-27-2.91-30.85-2.91C681.13,380.57,678.14,384.27,678.11,384.31Z"
          fill="#0071f2"
          data-primary="true"
          id="path218" />
        <g
          opacity="0.2"
          id="g222">
          <path
            d="M678.11,384.31l-.83-.66c.14-.17,3.34-4.13,7.42-4.13,3.55,0,26,2.38,30.28,2.84l-9-58.88,1-.15,9.16,60.22-.68-.07c-.27,0-27-2.91-30.85-2.91C681.13,380.57,678.14,384.27,678.11,384.31Z"
            id="path220" />
        </g>
        <path
          d="M671.38,402.62s4.12,4.77,6.32,5.68,34,3.63,54.84,2.27"
          fill="none"
          stroke="#6200a8"
          stroke-miterlimit="10"
          id="path224" />
        <path
          d="M610.78,337.44H526.14a3.62,3.62,0,0,0-3.47,4.67L540,399.34a7.26,7.26,0,0,0,6.94,5.15h88.64l-19-62.76a6,6,0,0,0-5.79-4.29"
          fill="#2e3135"
          id="path226" />
        <path
          d="M673.53,397.83H592.94v6.66h80.59a2.41,2.41,0,0,0,2.41-2.42v-1.83a2.41,2.41,0,0,0-2.41-2.41"
          fill="#2e3135"
          id="path228" />
        <path
          d="M606.57,337.44H521.92a3.63,3.63,0,0,0-3.47,4.67l17.32,57.23a7.26,7.26,0,0,0,7,5.15h88.63l-19-62.76a6,6,0,0,0-5.78-4.29"
          fill="#5d6166"
          id="path230" />
        <polygon
          points="552.13 356.41 526.08 356.41 522.27 343.05 548.31 343.05 552.13 356.41 552.13 356.41"
          fill="#2f2e41"
          data-secondary="true"
          id="polygon232" />
        <path
          d="M565.21,373.71a5,5,0,0,1,5-6.28,8,8,0,0,1,7.52,6.28,5,5,0,0,1-5,6.28,8,8,0,0,1-7.52-6.28Z"
          fill="none"
          stroke="#9ea0a3"
          stroke-miterlimit="10"
          id="path234" />
        <path
          d="M531.09,345.2l-3.55,4.62,3.55,4.62m10.9,0,3.56-4.62L542,345.2m-8,8.39,5.22-7.53"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="2"
          id="path236" />
        <polygon
          points="610.18 389.05 620.29 389.05 623.31 398.54 619.86 400.65 614.66 398.34 610.18 389.05 610.18 389.05"
          fill="#2f2e41"
          data-secondary="true"
          id="polygon238" />
        <path
          d="M615,398l4.84,2.15,2.94-1.81L620,389.5H610.9L615,398Zm4.91,3.17-5.58-2.47-4.86-10.1h11.16l3.23,10.15-4,2.42Z"
          fill="#fff"
          id="path240" />
        <polygon
          points="604.27 372.44 614.38 372.44 617.4 381.93 613.95 384.04 608.75 381.74 604.27 372.44 604.27 372.44"
          fill="#0071f2"
          data-primary="true"
          id="polygon242" />
        <path
          d="M609.08,381.39l4.84,2.14,2.94-1.8-2.81-8.83H605l4.09,8.49Zm4.91,3.16-5.58-2.47L603.55,372h11.16l3.23,10.14-4,2.42Z"
          fill="#fff"
          id="path244" />
        <path
          d="M684.86,647.9H608a1.3,1.3,0,0,1-1.3-1.29,34.18,34.18,0,0,1,.48-5.35c1-6,4.09-14.16,13.39-16.35,15.15-3.56,22.08-9,22.08-9s2.49-11.2,6.07-11.36,4.69,5.88,6.78,7.33,5.86,1.57,8.6,1.57c9.39,0,11.1-6.13,15.4-3.79,2.35,1.29,6.71,21,8.91,31.62.12.64.26,1.23.37,1.8a4,4,0,0,1-4,4.84"
          fill="#0071f2"
          data-primary="true"
          id="path246" />
        <path
          d="M684.86,647.9H608a1.3,1.3,0,0,1-1.3-1.29,34.18,34.18,0,0,1,.48-5.35h81.23c.12.64.26,1.23.37,1.8a4,4,0,0,1-4,4.84"
          fill="#2f2e41"
          data-secondary="true"
          id="path248" />
        <path
          d="M641.34,627.26a1,1,0,0,1-1-.62c-2.41-5.47-6-6.21-6.2-6.24a1.05,1.05,0,0,1,.36-2.07c.2,0,4.87.89,7.76,7.46a1.06,1.06,0,0,1-.54,1.39A1,1,0,0,1,641.34,627.26Zm7-2.54a1.05,1.05,0,0,1-1-.63c-2.4-5.47-6-6.2-6.19-6.23a1.06,1.06,0,0,1-.85-1.22,1,1,0,0,1,1.2-.85c.2,0,4.88.88,7.77,7.46a1.05,1.05,0,0,1-1,1.47Z"
          fill="#fff"
          id="path250" />
        <polygon
          points="648.51 596.87 673.68 591.39 674.95 597.91 648.51 596.87 648.51 596.87"
          fill="#f7a48b"
          id="polygon252" />
        <polygon
          points="554.44 591.53 577.84 603.32 579.43 596.66 554.44 591.53 554.44 591.53"
          fill="#f7a48b"
          id="polygon254" />
        <g
          opacity="0.2"
          id="g258">
          <path
            d="M666.29,593c-.85-4.65-20.76-113.89-18.73-123.7,1.18-5.65,21.76-12.7,39.91-18.91,14.06-4.81,27.33-9.36,28.19-12.09,2-6.33-.29-16.33-.31-16.43l.51-.12c.1.41,2.35,10.24.3,16.71-.94,3-13.72,7.36-28.51,12.43-18,6.17-38.46,13.16-39.57,18.52-2,9.71,18.52,122.36,18.73,123.49Z"
            id="path256" />
        </g>
        <g
          opacity="0.2"
          id="g262">
          <path
            d="M565.37,593l-.52-.06c.48-4.76,11.92-116.77,17-124.23s56.5-40.59,58.68-42l.29.44c-.54.35-53.54,34.49-58.53,41.85S565.49,591.8,565.37,593Z"
            id="path260" />
        </g>
        <path
          d="M664.45,300.26l-.17-.08c-.57-.28-14.07-7-17.25-15.18l1-.38c2.85,7.31,15,13.73,16.57,14.53,10.23-2.36,29.89-7.13,32.33-8.53,2.67-1.52,2.25-2.8,2.25-2.82l1-.39c0,.08.84,2.1-2.7,4.12-3.2,1.84-31.59,8.41-32.8,8.69Z"
          fill="#0071f2"
          data-primary="true"
          id="path264" />
        <g
          opacity="0.2"
          id="g268">
          <path
            d="M664.45,300.26l-.17-.08c-.57-.28-14.07-7-17.25-15.18l1-.38c2.85,7.31,15,13.73,16.57,14.53,10.23-2.36,29.89-7.13,32.33-8.53,2.67-1.52,2.25-2.8,2.25-2.82l1-.39c0,.08.84,2.1-2.7,4.12-3.2,1.84-31.59,8.41-32.8,8.69Z"
            id="path266" />
        </g>
        <path
          d="M672.1,295.91a135.47,135.47,0,0,1,9.68,41.53m-22.57-43.38s-3.79,8.83-6.62,43.38"
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          id="path270" />
        <path
          d="M684.11,337.44a2.33,2.33,0,1,1-2.33-2.33,2.33,2.33,0,0,1,2.33,2.33"
          fill="#fff"
          id="path272" />
        <path
          d="M654.92,337.44a2.33,2.33,0,1,1-2.33-2.33,2.34,2.34,0,0,1,2.33,2.33"
          fill="#fff"
          id="path274" />
        <polygon
          points="643.74 597.91 641.99 588.95 677.41 581.56 678.35 590.37 643.74 597.91 643.74 597.91"
          fill="#2f2e41"
          data-secondary="true"
          id="polygon276" />
        <polygon
          points="550.13 590.65 550.42 579.99 587.36 587.93 585.53 597.91 550.13 590.65 550.13 590.65"
          fill="#2f2e41"
          data-secondary="true"
          id="polygon278" />
        <polygon
          points="334.89 651.25 350.54 651.25 403.51 441.33 387.86 441.33 334.89 651.25 334.89 651.25"
          fill="#9ea0a3"
          id="polygon280" />
        <polygon
          points="385.85 449.3 401.5 449.3 403.51 441.33 387.86 441.33 385.85 449.3 385.85 449.3"
          fill="#5d6166"
          id="polygon282" />
        <polygon
          points="809.51 651.25 793.86 651.25 740.89 441.33 756.54 441.33 809.51 651.25 809.51 651.25"
          fill="#9ea0a3"
          id="polygon284" />
        <polygon
          points="758.55 449.3 742.9 449.3 740.89 441.33 756.54 441.33 758.55 449.3 758.55 449.3"
          fill="#5d6166"
          id="polygon286" />
        <path
          d="M359.23,402.57H792.57c.67,0,1.21,1.17,1.21,2.63v17.88c0,1.45-.54,2.62-1.21,2.62H359.23c-.67,0-1.22-1.17-1.22-2.62V405.2c0-1.46.55-2.63,1.22-2.63"
          fill="#e1e1e2"
          id="path288" />
        <path
          d="M368.86,425.7H782.94v15.63h-411c-1.7,0-3.08-3-3.08-6.66v-9Z"
          fill="#c2c4c6"
          id="path290" />
        <polygon
          points="368.86 425.7 782.94 425.7 782.94 431 368.86 431 368.86 425.7 368.86 425.7"
          fill="#9ea0a3"
          fill-rule="evenodd"
          id="polygon292" />
      </svg>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      elToAnimate: [],
    };
  },
  methods: {
    fadeIn() {
      this.elToAnimate.forEach((el, i) => {
        setTimeout(() => {
          el.classList.add('show');
        }, 300 * (i + 1));
      });
    },
  },
  mounted() {
    this.elToAnimate = document.querySelectorAll('.fade');
    this.fadeIn();
  },
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  flex-flow: column-reverse;
  justify-content: space-around;
  min-height: 100vh;
  text-align: center;
  padding: 1.5rem;
  color: #323;

  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }
  @media (min-width: 1200px) {
    align-items: center;
    justify-content: space-between;
  }

  .text {
    padding: 1.5rem;
    transform:
      perspective(1000px);
    box-shadow:
    rgba(255, 255, 255, 0.42) 0px 60px 123px -25px,
    rgba(255, 255, 255, 0.08) 0px 35px 75px -35px;
    border-radius: 1rem;
    border: 1px solid;
    border-color:
    rgba(76,155,246,1)
    rgba(100,155,246,1)
    rgba(150,200,246,1);
    background-color: rgba(255,255,255,.9);
    margin-bottom: 10vh;
    animation: bounceMobile 1s ease-in 0s 1 normal forwards;


    @media (min-width: 576px) {
      width: 70%;
      margin: 0 auto;
    }

    @media (min-width: 992px) {
      z-index: 2;
      width: 50%;
      transition: all .4s ease-in-out;
      transform:
        perspective(1000px)
        rotateY(10deg)
        translateY(100%);
      animation: bounce 1s ease-in 0s 1 normal forwards;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 5px;
      box-shadow: 0 30px 15px 2px rgba(0,0,0,.2);
      background-size: cover;
      background-repeat: no-repeat;
      transform: translateX(-50%);
      border-radius: 100%;
    }

    h1 {
      font-size: 2.5rem;
    }
    .subtitle {
      padding: .5rem;
      font-size: .8rem;
      font-style: italic;
    }
    .content {
      line-height: 1.5;
      padding: 0 1rem;
    }
  }

  .svg {
    display: none;

    @media (min-width: 768px) {
      display: block;
      
      svg {
        width: 100%;
        transform:
          perspective(800px)
          rotateY(-15deg);
      }
    }

  }

  @media (min-width: 1200px) {
    .text {

      width: 50%;
      margin: 0 auto;
      transform:
        perspective(1000px)
        rotateY(10deg)
        translateY(0);

      h1 {
        font-size: 3rem;
      }
      .subtitle {
        font-size: 1.2rem;
        padding: 1rem 0;
      }
      .content {
        font-size: 1.8rem;
        padding: 0 1rem;
      }
    }

    .slide-in {
      min-width: 60%;
    }
  }

  @media (min-width: 1500px) {
    .text {
      width: 35%;
    }
  }
}

.fade {
  transition: all .3s ease;
  opacity: 0;
  transform: translateY(50px);
}
.fade.show {
  opacity: 1;
  transform: translateY(0);
}

</style>
